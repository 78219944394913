.input-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 15px;
	height: 70px;
	min-width: 220px;

	.search {
		// background-color: saddlebrown;
		// height: 30px;
		width: 150px;
		margin: 1px;
	}

	input[type="date"] {
		::-webkit-input-placeholder {
			color: transparent;
		}

		:-moz-placeholder {
			/* Firefox 18- */
			color: transparent;
		}

		::-moz-placeholder {
			/* Firefox 19+ */
			color: transparent;
		}

		:-ms-input-placeholder {
			color: transparent;
		}
	}
}

.error-message {
	height: auto;
	color: red;
	font-size: 12px;
	text-align: right;
}
