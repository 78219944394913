@import "../../assets/scss/colors.scss";

.form-main-content {
	padding: 24px;

	.form-group {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.form-input-container {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
		}
	}

	.company-action-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.user-action-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.signup-main {
	display: flex;
	width: 100%;
	flex-direction: column;

	.signup-phase {
		display: flex;
		padding: 16px;
		gap: 16px;

		& > div {
			display: flex;
			flex: 1 1;
			padding: 8px;
			text-align: center;
			align-items: center;
			justify-content: center;
			background-color: var(--primary-color);
			color: white;
			cursor: pointer;
			text-transform: uppercase;
		}
	}

	.preview-data-main {
		display: flex;
		flex-direction: column;
		padding: 24px;
		gap: 25px;

		.section-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h2 {
				font-size: 20px;
				font-weight: 600px;
				text-transform: uppercase;
			}

			.form-edit-button {
				cursor: pointer;
				height: 32px;
				padding: 16px;
				background-color: var(--primary-color);
				color: var(--backgroung-color);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				font-size: 14px;
				transition: all 200ms ease-in-out;

				&:hover {
					transform: scale(1.1);
				}
			}
		}

		.preview-company,
		.preview-venue,
		.preview-user {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.section-body {
				display: flex;
				flex-wrap: wrap;
				gap: 16px;

				.form-data {
					flex: 1 0 30%;

					.data-label {
						font-size: 14px;
						color: #9d9d9d;
					}

					.data-value {
						font-size: 16px;
						font-weight: 400;
						color: #272727;
					}
				}
			}
		}
	}
}
