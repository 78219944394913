.subscription-main-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 16px;

	.cater-subscription-form {
		padding: 16px;

		.subscription-form-input-container {
			display: flex;
			gap: 16px;
			align-items: center;
			flex-wrap: wrap;

			.subscription-action-btn {
				margin-bottom: 25px;
				white-space: nowrap;
			}
		}
	}

	.cater-subscription-list {
		padding: 16px;

		.action-buttons-container {
			display: flex;
			gap: 16px;
		}
	}
}
