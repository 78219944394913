.login-wrapper {
    background-color: aliceblue;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-container {
        height: 500px;
        width: 450px;
        background-color: rgba(255, 255, 255, 0.664);
        border-radius: 10px;
        padding: 40px;
        box-sizing: border-box;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

        .login-form-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
        }

        // &.signup {
        //     padding: 15px 40px;
        //     height: 550px;
        // }

        .login-title-message {
            margin: 0;
            padding: 0;
        }

        .login-subtitle-message {
            margin-top: 5px;
            margin-bottom: 20px;
            color: gray;
        }

        .login-button {
            height: 50px;
            border-radius: 10px;
            font-size: 18px;
        }

        .forgot-pw-container {
            text-align: right;
            margin-bottom: 15px;
            color: rgba(0, 0, 255, 0.486);
            text-decoration: none;

            &:hover {
                color: rgba(0, 0, 255, 0.863);
            }
        }

        .register-container {
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
            color: rgba(0, 0, 255, 0.486);
            text-decoration: none;

            &:hover {
                color: rgba(0, 0, 255, 0.863);
            }

            // margin-top: 7px;
        }
    }
}