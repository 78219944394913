.container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 auto;
	// border: 1px solid red;
	max-width: 80% !important;

	label {
		margin: 10px 10px;
		font-size: 32px;
		font-weight: bold;
		color: purple;
		width: 200px;
	}
}

.box-design {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	// justify-content: center;
	gap: 10px;
	margin: 0;
	margin-bottom: 60px;
}
