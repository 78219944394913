.user-container {
	flex: 1;
	padding: 24px;
	overflow-y: scroll;
	height: 100vh;
	.user-container-inner {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
	}
}

.user-item-container {
	flex: 1;
	max-width: calc((100% / 3) - 16px);
	min-width: calc((100% / 3) - 16px);
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	border-radius: 4px;
	background-color: #f8f8fc;
	box-shadow: 0px 0px 4px #dddddd;
	font-size: 1em;
	transition: all 200ms ease-in-out;

	&:hover {
		box-shadow: 0px 0px 12px #dddddd;

		.item-details-container .item-info .info-icon {
			&.a {
				background-color: #46be46;
				color: #f8f8fc;
			}

			&.b {
				background-color: #3498db;
				color: #f8f8fc;
			}

			&.c {
				background-color: #523563;
				color: #f8f8fc;
			}
		}
	}

	.item-img-container {
		align-self: center;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		border: 2px solid #f8f8fc;
		position: relative;

		.user-icon {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			border-radius: 50%;
			object-fit: contain;
		}

		.user-verified {
			position: absolute;
			bottom: 4px;
			right: 8px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 1px solid #f8f8fc;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 900;
			color: #f8f8fc;

			&.active {
				background-color: #46be46;
			}

			&.offline {
				background-color: #b53e2c;
			}
		}
	}

	.item-details-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 8px;

		.user-online {
			padding: 4px;
			border-radius: 50%;

			&.active {
				color: #f8f8fc;
				background-color: #46be46;
			}

			&.offline {
				color: #f8f8fc;
				background-color: #b53e2c;
			}
		}

		h4.item-mainText {
			color: #272727;
			font-size: 1.4em;
			font-weight: 600;
			text-align: center;
			text-transform: capitalize;
		}

		.details-divider {
			width: 50%;
			height: 4px;
			border-radius: 4px;
			background-color: #523563;
			margin-top: 4px;
		}

		.item-info {
			display: flex;
			align-items: center;
			gap: 16px;
			margin: 8px 0;

			.info-icon {
				width: 32px;
				min-width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: #f1f1fc;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #9d9d9d;
				transition: all 200ms ease-in-out;
			}

			.info-details {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 4px;
				text-transform: lowercase;

				> .subText {
					font-size: 1em;
					color: #9d9d9d;
					font-weight: 400;
				}

				> .mainText {
					font-size: 1.1em;
					color: #272727;
					font-weight: 400;
				}
			}
		}

		.item-action-btn {
			align-self: flex-end;
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			font-size: 1.1em;
			color: #3498db;
			transition: all 350ms ease-in-out;
			margin-top: 8px;

			&:hover {
				transform: scale(0.9);
			}

			i {
				font-size: 0.9em;
			}
		}
	}
}

@media screen and (max-width: 860px) {
	.user-item-container {
		max-width: 100%;
		min-width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.user-item-container {
		max-width: calc(50% - 12px);
		min-width: calc(50% - 12px);
	}
}

@media screen and (min-width: 1440px) {
	.user-item-container {
		max-width: calc(25% - 18px);
		min-width: calc(25% - 18px);
	}
}

@media screen and (min-width: 1660px) {
	.user-item-container {
		max-width: calc(20% - 20px);
		min-width: calc(20% - 20px);
	}
}
