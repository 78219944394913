.card-container {
	width: 255px;
	margin-bottom: 10px;
}

.card-category {
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 10px;
	align-items: center;
}

.card-body {
	h2 {
		font-size: 16px;
	}
}

.vCompanies {
	color: green;
}

.uCompanies {
	color: #e74c3c;
}
